.ExpandableView {
    background-color: transparent;
    /* font-family: Cambay; */
}

.Outlets {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.Outlets li {
    display: inline-block;
        list-style: none;
}

.Outlet {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.Outlet:hover {
color: #FFF;
    background: #5BA6E6;

}

.OutletName {
    font-weight: 600;
}

.OutletLocation {
    font-weight: 400;

}

.ExpandableMember {
    float: left;
    text-decoration: none;
}

.ExpandableMemberName {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.OutletListArea {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.MemberContractDetailArea {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.MemberContractDetailArea header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.ExpandableMemberWrapper {
    background: transparent;
}

.clearfix::before,
.clearfix::after {
    display:table;
    content: " ";
}

.clearfix::after {
clear:both;
}


.FuneralSchemeWrapper {
    margin: 0 2.5rem;
}

.AddNewMember {
float:right;
margin-right: 8.8rem;
}

.AddNewMemberBtn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.FuneralSchemeTable  {
    width: 100%;
    border-collapse: collapse;
}

.FuneralSchemeTable th {
    text-align: left;
}

.FuneralSchemeTable th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.FuneralSchemeTable td,
.FuneralSchemeTable th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.FuneralSchemeTable .Totals td {   
     border-bottom: none;
     font-weight: 600;
}

.FuneralSchemeTable .MoneyTotalLabel {
    float: right;

}
.FuneralSchemeTable .MoneyTotal {

}
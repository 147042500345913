.Logo {
    background-color: transparent;
    padding: 0;
    height: 4rem;
    box-sizing: border-box;
}

.Logo {
    display: inline-block;
    font-size: 24px;
    line-height: 24px;
    vertical-align: middle;
}

.btn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.btn:focus,
.btn:hover,
.btn:active,
.btn:disabled {
    background-color: #6d5eac;
}
.Container {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Tools {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.PageFilter {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.ToolsWrapper {
    height: 6rem;
    width: 100%;

}

.PrintToolsWrapper {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.AddNewMerchant {
    /* // font-family: Cambay; */
}

.AddNewMerchantBtn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.AddNewMerchantBtn:focus,
.AddNewMerchantBtn:hover,
.AddNewMerchantBtn:active {
    background-color: rgb(51, 137, 255);
}


.ToolsDataExportIconWrapper {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ToolsPrintIconWrapper {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.FilterInput {
    margin-right: 2rem;
}

.FormGroup {
    /* margin-bottom: 10px; */
}

.FormGroupDefault.has-error {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.FormGroupDefault {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.FormGroupDefault label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.FormGroup label:not(.error) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.FormControl {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.MerchantTableWrapper {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.TableWrapper {
    display: flex;
}

.MerchantTable {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.MerchantTable th {
    text-align: left;
}





.MerchantTable th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.MerchantTable td,
.MerchantTable th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
}


.MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}

.RevealMoreHeader {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Row>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.dataTables_paginate {
    float: right;
}

.dataTables_paginate {
    /* margin-top: 25px !important; */
}

div.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.dataTables_paginate ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.dataTables_paginate ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.dataTables_paginate ul>li.disabled a {
    opacity: .5;
}

.dataTables_paginate ul>li.disabled a {
    opacity: .35;
}

.dataTables_paginate ul>li.active>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.dataTables_paginate ul>li.next>a,
.dataTables_paginate ul>li.prev>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pg_arrow_right {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pg_arrow_left {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pg_arrow_right:before {
    content: "\e631";
}

.pg_arrow_left:before {
    content: "\e629";
}

.btn {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.btn_primary,
.btn_primary:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.btn_cons {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.MerchantRow {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.MerchantRow:hover {
    background-color: #267788;
    color: #fff;

}

.MerchantRow:hover a {
    color: #fff;
}

.MerchantRowExpanded {
    background: #267788;
    color: #fff;
}

.table.table-condensed.table-detailed>tbody>tr.shown>td {
    background: #fdf5df;
}

.EnableComputer {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.EnableComputerHeader {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.EnableComputerBody {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}
.Container {
  /* background-color: #fff; */
  flex-direction: column;
  height: 100%;
  font-size: 1.4rem;
}

.BreadcrumbWrapper {
  display: flex;
  background-color: transparent;
  width: 100%;
  padding-left: 2.4rem;
  flex: 0 0 7.2rem;
  height: 7.2rem;
}

.Content {
  flex: 1 1 auto;
  display: flex;
  margin: 2.4rem;
  flex-direction: column;
}

.CardBody {
  margin-top: 1rem;
}

.Alert {
  padding: 18px;
  margin-bottom: 15px;
  border: 0;
  border-radius: 0;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.Breadcrumb {
  padding: 1.8rem 0;
  flex: 0 0 50%;
}

.Header {
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 1.5px;
  /* text-transform: uppercase; */
}

.ByLine {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.Activity {
  background: #fff;
  flex: 0 0 36rem;
  /* width: 36rem; */
}

.WelcomeContainer {
  display: flex;
  /* margin: 0 2.4rem 0 0; */
  /* height: 17.6rem; */
}

.MerchantProfile {
  display: flex;
  background: transparent;
  flex: 0 0 100%;
}

.TabHeader {
  /* padding: 0px 2rem; */
  margin-bottom: 1rem;
  display: flex;
}

.PageHeader {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  width: auto;
}

.Header {
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
}

.ExportReport {
  flex: 1 1 50%;
  width: auto;
  margin-right: 1.4rem;
  align-items: center;
  display: flex;

  line-height: normal;
  flex: 1 1 auto;
  justify-content: flex-end;
}

.ExportIconWrapper {
  margin: 0 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
}

.EmailIconWrapper {
  margin: 0 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
}

.AddUser {
  display: flex;
  margin-right: 20px;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.AddUserBtn {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

.Tools {
  /* display: flex; */
  margin: 1rem 0 3.5rem;
  /* padding-top: 1.4rem; */
}

.PageFilter {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-start;
  width: auto;
  /* margin-left: 15px; */
}

.PrintToolsWrapper {
  display: flex;
  flex: 1 1 20%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}

.FilterReportBtn {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  height: 3.4rem;
  padding: 8px;
  text-decoration: none;
  border: none;
  display: block;
}

.AddNewMerchantBtn:focus,
.AddNewMerchantBtn:hover,
.AddNewMerchantBtn:active {
  background-color: rgb(51, 137, 255);
}

.ToolsDataExportIconWrapper {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.ToolsPrintIconWrapper {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.FilterInput {
  margin-right: 2rem;
}

.FormGroupDefault.has-error {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.FormGroupDefault {
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.FormGroupDefault label {
  margin: 0;
  display: block;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.FormGroup label:not(.error) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.FormControl {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.MerchantTableWrapper {
  margin-top: 3rem;
  white-space: nowrap;
}

.MerchantTable {
  width: 100%;
  border-collapse: collapse;
}

.MerchantTable th {
  text-align: right;
}

.MerchantTable th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.MerchantTable td,
.MerchantTable th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.RevealMoreHeader {
  padding: 0;
  vertical-align: middle;
}

.Row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 2.5rem;
}

.Row > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.dataTables_paginate {
  float: right;
}

div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.dataTables_paginate ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

.dataTables_paginate ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.dataTables_paginate ul > li.disabled a {
  opacity: 0.5;
}

.dataTables_paginate ul > li.disabled a {
  opacity: 0.35;
}

.dataTables_paginate ul > li.active > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.dataTables_paginate ul > li.next > a,
.dataTables_paginate ul > li.prev > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pg_arrow_right {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pg_arrow_left {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pg_arrow_right:before {
  content: "\e631";
}

.pg_arrow_left:before {
  content: "\e629";
}

.btn {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.btn_primary,
.btn_primary:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.btn_cons {
  min-width: 120px;
}

.MerchantName {
  font-weight: 500;
}

.Icn {
  cursor: pointer;
  /* fill:#000; */
}

.Icn:hover {
  cursor: pointer;
}

.RevealMore {
  background: #e1e1e1;
  cursor: pointer;
  width: 2.4rem;
}

.RevealMore:hover {
  background: transparent;
  color: #707070;
  fill: #fff;
}

.RevealMore div {
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}

.RevealMoreExpanded {
  background: transparent;
  fill: #fff;
  transform: rotate(90deg);
}

.Expandabled {
  text-align: left;
  background: #d7effb;
  border-bottom: 1px solid rgb(56, 0, 60);
  display: table-row;
  z-index: 500;
}

.Expandabled td {
  padding: 2rem 2rem 2rem 5.6rem;
  border-bottom: 1px solid #e8e8e8;
}

.MerchantTable td:last-child {
  border-right: 1px solid #e8e8e8;
}

.MerchantTable td:first-child {
  border-left: 1px solid #e8e8e8;
}

.MerchantRow {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.MerchantRow:hover {
  background-color: #267788;
  color: #fff;
}

.MerchantRow:hover a {
  color: #fff;
}

.MerchantRowExpanded {
  background: #267788;
  color: #fff;
}

.table.table-condensed.table-detailed > tbody > tr.shown > td {
  background: #fdf5df;
}

.Totals td {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid #e8e8e8;
  font-weight: 700;
}

.MoneyTotalLabel {
  float: right;
}

.FilterWrapper {
  display: flex;
  justify-content: flex-start;
  /* border-right: solid #707070; */
}

.FilterPeriods {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  flex-flow: row;
  /* height: 100%; */
  padding-left: 2rem;
  color: rgb(0, 0, 0);
  justify-content: flex-start;
  /* margin-right: 3.2rem; */
}

.FilterPeriods li {
  font-weight: 600;
  padding-right: 2rem;
  line-height: 38px;
}

.FinancialsIntro {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.Bold {
    font-weight: 800;
}

.bold {
    font-weight: 600;
}


.LoginWrapper {
    border-right: solid 1px #E6E8EB;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    font-weight: 300;
    color: #002060;
}

.LoginWrapper .LoggedUser {
    font-weight: 600;
}

.SettingsHelpIconWrapper {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.SettingsIconWrapper {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}
/* .Odd {
}

.Even {
} */

.MemberName {
    font-weight: 500;
}

.Icn {
    cursor: pointer;
    /* fill:#000; */
}

.Icn:hover {
    cursor: pointer;

}



.RevealMore {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.RevealMore:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.RevealMore div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.RevealMoreExpanded {
    background: transparent;
    fill:#fff;
    transform: rotate(90deg);
}

 .Expandabled {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Expandabled td {
    padding: 2rem 2rem 2rem 5.6rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .MemberRow {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.MemberRow:hover {
    background-color: #267788;
    color:#fff;

    }

    .MemberRow:hover a {
        color:#fff;
        }

    .MemberRowExpanded {
        background: #267788;
        color:#fff;
    }

.table.table-condensed.table-detailed > tbody > tr.shown > td {
    background: #fdf5df;
}

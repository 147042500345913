.flex-children {
    display: flex;
}

.left-nav-container {
    position: absolute;
    height: 100%;
    z-index: 2;
}

.flex-columns>.flex-fixed {
    flex: 0 0 auto;
}

.SidebarContainer {
    /* height: 100vh; */
    display: flex;
    flex: 0 0 auto;
    background-color: #f9f9f9;
    /* border-right: 1px solid #f1f3f4; */
    border-right: 1px solid #ddd;
    
}

.SidebarView {
    flex: auto;
    overflow-y: auto;
    overflow-x: hidden;
    width: 21.6rem;
    margin-top: 9.6rem;
}

.CompanyLogo {
    /* height: 7.2rem; */
    line-height: 4.8rem;
    color: #fff;
    padding: 1.2rem 0;
    padding-left: 1.6rem;
}

.Bold {
    font-weight: 800;
}

.left-nav-view .primaryNavItem {
    font-family: "Avenir Next forINTUIT", "Arial", -apple-system, "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    width: 165px;
}
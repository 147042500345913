.CardBody {
    margin-top: 1rem;
}

.LabelStyle {
    color: #000;
    font-weight: 400;
}

.IconWrapper {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 36px;
    width: auto;
}

.ExpandableOutlet {
    float: left;
    text-decoration: none;
}

.ExpandableOutletName {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.AddNewOutlet {
    float: right;
    margin-right: 8.8rem;
}

.AddNewOutletBtn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.Outlets {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* margin-left: 0.6rem; */
}

.Outlets li {
    display: inline-block;
    list-style: none;
}

.Outlet {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
}

.Outlet:hover {
    color: #FFF;
    background: #5BA6E6;

}

.OutletName {
    font-weight: 600;
}

.OutletLocation {
    font-weight: 400;

}

.TabHeader {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.CardBody {
    margin-top: 3rem;
}

.PageHeader {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Tools {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.ToolsExportIconWrapper {
    display: inline-block;
    margin-left: 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.AddUserBtn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.Details {
    display: flex;
    margin-bottom: 1rem;
}

.DetailsContent {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.DetailsEdit {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.025);
    /* border-left: 1px solid rgba(0, 0, 0, 0.05); */
    animation: slide 0.5s forwards;
    animation-delay: 2s;
}

.FieldWrapper:hover {
    background-color: rgba(0, 0, 0, .12);
    padding: 0 1rem;
    cursor: pointer;

}

@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}

.MerchantProfile {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}


.FilterInput {
    margin-right: 2rem;
}

.FormGroup {
    margin-bottom: 10px;
}

.FormGroupDefault.has-error {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.FormGroupDefault {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

.PageFilter {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
    margin-top: 10px;
}

.PageTitle {
    border-right: 1px solid #707070;
    padding-right: 2rem;
    font-weight: 700;
    margin-top: 10px;
}

.FilterInput {
    margin-right: 2rem;
}

.FormGroup {
    margin-bottom: 10px;
}

.FormGroupDefault.has-error {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.FormGroupDefault {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.FormGroupDefault label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.FormGroup label:not(.error) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.FormControl {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}
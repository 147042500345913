.NavigationItem {
    display: flex;
    line-height: 18px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Roboto';
    font-size: 1.4rem;
    font-weight: 400;
}

.NavigationItem a {
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 1.3rem 1.8rem;
    border-left: 6px solid transparent;
    -webkit-font-smoothing: antialiased;
    font-size: 1.4rem;
    letter-spacing: 1.2px;
    color: #202124;
    text-shadow: none;
}


.NavigationItem a.hover {
    color: #d7effb
}

.NavigationItem a:hover {
    font-weight: 700;
    color: #292B2C;
    border: 0;
    border-left: 6px solid #4680A0;
    background-color: #FFF;
    border-color: #4680A0;
}


.NavigationItem a.Selected {

    font-weight: 700;
    color: #002060;
    border: 0;
    border-left: 6px solid #4680A0;
    background-color: #FFF;
    border-color: #4680A0;
}
.Wizard ul.Progress {
    position: relative;
    list-style-type: none;
    margin: 20px 0px 0px;
    padding: 0px;
}

.cf {
    zoom: 1;
}



.Wizard ul.Progress li {
    /* margin: 0px 2% 0px 0px;
    float: left;
    text-align: center;
    width: 32%;
    position: relative;
    z-index: 3; */

    margin: 0px 2% 0px 0px;
    float: left;
    text-align: center;
    width: 31.25%;
    position: relative;
    z-index: 3;
}

.Active {
    color: rgb(96, 96, 96);
    font-weight:500;
}



.Step {
    display: block;
    padding: 15px 12px 10px;
    font-size: 1.4rem;
    line-height: 23px;
    text-decoration: none;
    color: rgb(106, 106, 106);
    float: none;
}

.Wizard ul.Progress span.Number {
    display: block;
    margin: 0px auto 1rem;
    border-radius:  3.2rem;
    height:  3.2rem;
    width:  3.2rem;
    text-align: center;
    color: rgb(153, 153, 153);
    font-size: 18px;
    background: rgb(225, 225, 225);
    line-height:  3.2rem;
}

.Wizard ul.Progress li.Future span.Number {
    color: rgb(181, 181, 181);
}

.Wizard ul.Progress span.Number {
    background: rgb(243, 243, 243);
}

ul.Progress li.Active span.Number {
    background: rgb(102, 51, 153);
    color: rgb(255, 255, 255);
}

em {
    font-style: normal;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: block;
    color: rgb(153, 153, 153);
}

.Active em {
    color: rgb(102, 102, 102);
}

.Wizard ul.Progress li.Split {
    margin: 0px;
    float: left;
    width: 24%;
    position: absolute;
    top: 30px;
    height: 1px;
    background: rgb(225, 225, 225);
}

ul.Progress li.split {
    background: rgb(225, 225, 225);
}

.Wizard ul.Progress li.Split.One {
    left: 20%;
}

.Wizard ul.Progress li.Split.Two {
    left: 54%;
}

.TabContent {
    overflow: hidden;
    padding: 0.1rem;
}
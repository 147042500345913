/* .Odd {
}

.Even {
} */

.MemberName {
  font-weight: 500;
}

.Icn {
  cursor: pointer;
  /* fill:#000; */
}

.Icn:hover {
  cursor: pointer;
}

.RevealMore {
  background: #e1e1e1;
  cursor: pointer;
  width: 2.4rem;
}

.RevealMore:hover {
  background: transparent;
  color: #707070;
  fill: #fff;
}

.RevealMore div {
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}

.RevealMoreExpanded {
  background: transparent;
  fill: #fff;
  transform: rotate(90deg);
}

.Expandabled {
  text-align: left;
  background: #d7effb;
  border-bottom: 1px solid rgb(56, 0, 60);
  display: table-row;
  z-index: 500;
}

.Expandabled td {
  padding: 2rem;
  border-bottom: 1px solid #e8e8e8;
}

.ToolsDataExportIconWrapper {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.PrintToolsWrapper {
  display: flex;
  flex: 1 1 20%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}

.AddUser {
  display: flex;
  margin-right: 20px;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.AddUserBtn {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 0.8rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  display: block;
}

.MemberRow {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.MemberRow:hover {
  background-color: rgb(38, 119, 136, 0.3);

  color: #fff;
}

.MemberRow:hover a {
  color: #fff;
}

.MemberRowExpanded {
  background: #267788;
  color: #fff;
}

.table.table-condensed.table-detailed > tbody > tr.shown > td {
  background: #fdf5df;
}

.TabList {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.TabListItem {
  /* display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem; */

  display: block;
  border-radius: 0;
  padding: 0.5rem 2rem;
  margin-right: 0;
  font-weight: 500;
  letter-spacing: .06em;
  color: #000;
  font-size: 85%;
  font-weight: 300;
  min-width: 14rem;
  text-transform: uppercase;
  text-align: center;
  border-color: transparent;
  position: relative;
  line-height: 1.8em;
  user-select: none;
}



.TabListItem:after {
  position: absolute;
  -webkit-transition: all .1s linear 0s;
  transition: all .1s linear 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  display: block;
  background-color: #524f4f;
  height: 0;
  content: '';
  left: 0;
  z-index: 125;
  top: 100%;
}



.TabListActive {
  background-color: white;
  font-weight: 700;
  /* border: solid #ccc;
    border-width: 1px 1px 0 1px; */
}

.TabListActive:after,
.TabListItem:hover:after {
  height: 3px;
  font-weight: 700;
}

/* .nav-tabs>li>a {
    display: block;
    border-radius: 0;
    padding: 13px 20px;
    margin-right: 0;
    font-weight: 500;
    letter-spacing: .06em;
    color: rgba(87,87,87,.7);
    font-size: 10.5px;
    min-width: 70px;
    text-transform: uppercase;
    border-color: transparent;
    position: relative;
    line-height: 1.7em;
} */
.Tabs {
  background: transparent;
  margin-bottom: 3rem;
}

.TabList {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.TabListItem {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  user-select: none;
}

.TabListActive {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.TabContent {
  background: transparent;
  margin-right: 0;
  margin-left: 0;
}

.FuneralSchemeMember {
  /* color: #666; */
  /* vertical-align: top; */
  font-size: 1.2rem;
  /* font-family: Raleway; */
  /* font-weight: 600; */
  /* vertical-align: middle; */
  padding: 1rem;
}
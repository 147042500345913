.close {
    float: right;
    font-size: 1.5rem;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close {
    font-weight: 700;
    line-height: 1;
}

 .close:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.pg-close:before {
    content: "\e60a";
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}
.btn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
    float: right !important;
}


.btn:focus,
.btn:hover,
.btn:active,
.btn:disabled {
    background-color: #6d5eac;
}

.btnDefault, .btnDefault:focus {
    color: #5e5e5e;
    background-color: #fff;
    border-color: #e7e7e7;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .01em;
    margin-bottom: 0;
    border: 1px solid #e7e7e7;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    text-shadow: none;
    box-shadow: none;
    line-height: 21px;
    position: relative;
    transition: color .1s linear 0s, background-color .1s linear 0s, opacity .2s linear 0s !important;
    padding: 6px 17px;
}
.FullHeight {
    height: 100% !important;
}

.LoginContainer {
    width: 40rem;;
    margin: auto;
    height: 100%;
    margin-top: 7.2rem;
}

.LoginWrapper {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    margin-top: 7.2rem;
}

.Logo {
    background-color: transparent;
    padding: 0;
    height: 4rem;
    box-sizing: border-box;
}

.Logo {
    display: inline-block;
    font-size: 24px;
    line-height: 24px;
    vertical-align: middle;
}

.PaddingTop40 {
    padding-top: 4rem;
}

.PaddingTop15 {
    padding-top: 1.5rem;
}

.FormGroup {
    margin-bottom: 1.5rem;
}

.FormGroupDefault.has-error {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.FormGroupDefault {
    background-color: #fff;
    position: relative;
    border: 1px solid rgba(0, 0, 0, .07);
    border-radius: 2px;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;
    overflow: hidden;
    width: 100%;
    -webkit-transition: background-color .2s ease;
    transition: background-color .2s ease;
}

label,
input,
button,
select,
textarea {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.FormGroupDefault label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
}

.FormGroup label:not(.error) {
    font-size: 10.5px;
    letter-spacing: .06em;
    text-transform: uppercase;
    font-weight: 500;
}

.FormControl {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.FormControl {
    background-color: #fff;
    background-image: none;
    border: 1px solid rgba(0, 0, 0, .07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    padding: 8px 12px;
    line-height: normal;
    font-size: 14px;
    font-weight: 400;
    vertical-align: middle;
    min-height: 35px;
    -webkit-transition: all .12s ease;
    transition: all .12s ease;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-transition: background .2s linear 0s;
    transition: background .2s linear 0s;
}

.FormGroupDefault .FormControl {
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: 0 0;
}

.Error {
    font-size: 12px;
    color: #f35958;
    display: block;
}

.HasError .FormControl {
    border-color: #f35958;
    -webkit-box-shadow: none;
    box-shadow: none;
}



.Row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

form .Row {
    margin-left: 0;
    margin-right: 0;
}

.SignIn {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 !important;
}

.Help {
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    justify-content: flex-end;
    align-items: center!important;
    padding-left: 7px;
    padding-right: 0;
}

.Radio label:before, .Checkbox label:before {
    -webkit-box-sizing: inherit;
    box-sizing: border-box;
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    background-color: #fff;
    border: 1px solid #cdcdcd;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.Checkbox label:before {
    top: 1.4px;
    border-radius: 3px;
    transition: border .2s linear 0s,color .2s linear 0s;
}

label, input, button, select, textarea {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.Radio, .Checkbox {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 0;
}

.Checkbox label {
    transition: border .2s linear 0s,color .2s linear 0s;
}

.Radio label, .Checkbox label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    min-width: 17px;
    min-height: 17px;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

label, input, button, select, textarea {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}


input[type=radio], input[type=checkbox] {
    margin-top: 1px 0 0;
    line-height: normal;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}



input[type=radio], input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}

.Checkbox input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;
}

input, input:focus {
    -webkit-transition: none!important;
}

.ButtonPrimary {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .01em;
    -webkit-font-smoothing: antialiased;
    margin-top: 10px;
    margin-bottom: 0;
    margin-right: 5px;
    min-width: 120px;
    border: 1px solid #e7e7e7;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-border-radius: 3px;
    background-image: none!important;
    color: #fff;
    background-color: #6d5eac;
    border-color: #6d5eac;
    text-shadow: none;
    box-shadow: none;
    line-height: 21px;
    position: relative;
    transition: color .1s linear 0s,background-color .1s linear 0s,opacity .2s linear 0s!important;
    padding: 6px 17px;
}

.LoginBtn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.LoginBtn:focus,
.LoginBtn:hover,
.LoginBtn:active,
.LoginBtn:disabled {
    background-color: #6d5eac;
}

p {
    display: block;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .01em;
    line-height: 22px;
    margin: 0 0 10px;
    font-style: normal;
    white-space: normal;
}